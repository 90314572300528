<template>
  <div style="margin: 20px" v-loading="isLoading">
    <div class="flex justify-between items-center">
      <p class="font-bold mt-3 text-lg" style="margin-left: 10px">
        Activity Sandbox
      </p>
      <div class="custom-btn mt-3" @click="addActivity">New Activity</div>
    </div>
    <div
      class="bg-white py-3 px-4 mt-3"
      style="margin: 12px 0px 0; border-radius: 5px"
    >
      <p class="text-sm">
        This is your personal activity management panel. The information
        template is similar to the one in the Common Application system. You can
        use this panel as a draft paper for your activity list. This page is not
        open to the review committee.
      </p>
    </div>
    <div v-for="(item, index) in dataList" :key="index">
      <Activity
        :info="item"
        :index="index"
        @edit="editActivity(item)"
        @delete="deleteActivity(item)"
      />
    </div>
  </div>
</template>

<script>
import { getMyActivities, removeActivity } from "../../api/eae";
import { getUserId } from "../../utils/store";
import { formatDate } from "../../utils/date";
import Activity from "../../components/Activity.vue";

export default {
  name: "index",
  data() {
    return {
      dataList: [],
      activityInfo: {
        id: "",
        student_id: getUserId(),
        type: "",
        position: "",
        org: "",
        desc: "",
        hours_peer_week: "",
        start_time: "",
        end_time: "",
      },
      isLoading: false,
    };
  },
  components: {
    Activity,
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      getMyActivities(getUserId())
        .then((res) => {
          let result = res.data.data;
          this.dataList = result.map((item) => {
            if (item.start_time) {
              item.show_start_time = formatDate(
                new Date(item.start_time * 1000),
                "yyyy-MM"
              );
            } else {
              item.show_start_time = "";
            }
            if (item.end_time) {
              item.show_end_time = formatDate(
                new Date(item.end_time * 1000),
                "yyyy-MM"
              );
            } else {
              item.show_end_time = "";
            }
            return item;
          });
          this.dataList = res.data.data;
        })
        .finally(() => (this.isLoading = false));
    },
    editActivity(row) {
      this.$router.push({
        path: "activityEdit",
        query: {
          id: row._id.$id,
        },
      });
    },
    async deleteActivity(row) {
      try {
        const res = await removeActivity({ activity_id: row._id.$id });
        if (res.data.code === 0) {
          this.$message.success("Deleted Successfully");
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    addActivity() {
      this.$router.push({
        path: "activityEdit",
        query: {
          id: "",
        },
      });
    },
  },
};
</script>

<style scoped>
.custom-btn {
  background: #ff6450;
  border-radius: 5px;
  padding: 6px 10px;
  color: white;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
}
</style>
